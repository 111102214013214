const tocList = document.querySelector('.js-toc-list');

if (tocList) {
    const tocContent = document.querySelector('.js-toc-content');
    const headers = tocContent.querySelectorAll('h2, h3');
    let items = [];
    let mode = 'h2';
    for (const header of headers) {
        const id = header.getAttribute('id');
        if (!id) {
            console.error(`Every h2/h3 on the page should have id attribute. Header "${header.textContent}" haven't.`);
            continue;
        }

        const tag = header.tagName.toLowerCase();
        if (tag === 'h3' && mode !== tag) {
            mode = tag;
            items.push('<ul>');
        } else if (tag === 'h2' && mode === 'h3') {
            mode = tag;
            items.push('</ul>');
        }

        items.push(`<li><a class="link" href="#${id}">${header.innerHTML}</a></li>`);
    }

    tocList.innerHTML = items.join('');
}