const videosContainer = document.querySelector('.js-videos');

if (videosContainer) {
    function createVideoIframe(id) {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `https://www.youtube.com/embed/${id}?rel=0&showinfo=0&autoplay=1`);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', '');
        iframe.classList.add('video__media');
        return iframe;
    }

    videosContainer.addEventListener('click', (event) => {
        const link = event.target.closest('.js-video-link');

        if (!link) {
            return;
        }
        event.preventDefault();

        const container = link.parentNode;
        const id = link.getAttribute('data-id');
        const videoIframe = createVideoIframe(id);
        container.removeChild(link);
        container.appendChild(videoIframe);
    });
}
